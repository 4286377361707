module.exports = {
  home: "\ue600",
  home2: "\ue601",
  home3: "\ue602",
  home4: "\ue603",
  home5: "\ue604",
  home6: "\ue605",
  pencil: "\ue606",
  pencil2: "\ue607",
  edit: "\ue608",
  edit2: "\ue609",
  feather: "\ue60a",
  feather2: "\ue60b",
  pen: "\ue60c",
  brush: "\ue60d",
  paintbrush: "\ue60e",
  paintRoller: "\ue60f",
  eyeDropper: "\ue610",
  magic: "\ue611",
  design: "\ue612",
  magnet: "\ue613",
  aim: "\ue614",
  gun: "\ue615",
  droplet: "\ue616",
  droplet2: "\ue617",
  fire: "\ue618",
  lighter: "\ue619",
  knife: "\ue61a",
  toiletPaper: "\ue61b",
  umbrella: "\ue61c",
  sunSmall: "\ue61d",
  sun: "\ue61e",
  moon: "\ue61f",
  cloud: "\ue620",
  cloudUpload: "\ue621",
  cloudDownload: "\ue622",
  cloudRain: "\ue623",
  cloudSnow: "\ue624",
  cloudFog: "\ue625",
  cloudLightning: "\ue626",
  cloudSync: "\ue627",
  cloudLock: "\ue628",
  cloudGear: "\ue629",
  cloudDatabase: "\ue62a",
  database: "\ue62b",
  shield: "\ue62c",
  lock: "\ue62d",
  unlock: "\ue62e",
  key: "\ue62f",
  keyHole: "\ue630",
  gear: "\ue631",
  gear2: "\ue632",
  wrench: "\ue633",
  tools: "\ue634",
  hammer: "\ue635",
  factory: "\ue636",
  factory2: "\ue637",
  recycle: "\ue638",
  trash: "\ue639",
  trash2: "\ue63a",
  heart: "\ue63b",
  heart2: "\ue63c",
  flag: "\ue63d",
  flag2: "\ue63e",
  flag3: "\ue63f",
  atSign: "\ue640",
  envelope: "\ue641",
  inbox: "\ue642",
  paperclip: "\ue643",
  reply: "\ue644",
  replyAll: "\ue645",
  paperPlane: "\ue646",
  eye: "\ue647",
  eye2: "\ue648",
  binoculars: "\ue649",
  binoculars2: "\ue64a",
  floppyDisk: "\ue64b",
  printer: "\ue64c",
  file: "\ue64d",
  folder: "\ue64e",
  copy: "\ue64f",
  scissors: "\ue650",
  paste: "\ue651",
  clipboard: "\ue652",
  clipboardCheck: "\ue653",
  register: "\ue654",
  enter: "\ue655",
  exit: "\ue656",
  papers: "\ue657",
  news: "\ue658",
  document: "\ue659",
  document2: "\ue65a",
  license: "\ue65b",
  graduationHat: "\ue65c",
  license2: "\ue65d",
  medal: "\ue65e",
  medal2: "\ue65f",
  medal3: "\ue660",
  medal4: "\ue661",
  podium: "\ue662",
  trophy: "\ue663",
  musicNote: "\ue664",
  music: "\ue665",
  music2: "\ue666",
  playlist: "\ue667",
  shuffle: "\ue668",
  headset: "\ue669",
  presentation: "\ue66a",
  play: "\ue66b",
  filmPlay: "\ue66c",
  camera: "\ue66d",
  photo: "\ue66e",
  picture: "\ue66f",
  book: "\ue670",
  bookClosed: "\ue671",
  bookmark: "\ue672",
  bookmark2: "\ue673",
  books: "\ue674",
  library: "\ue675",
  contacts: "\ue676",
  profile: "\ue677",
  user: "\ue678",
  users: "\ue679",
  users2: "\ue67a",
  woman: "\ue67b",
  man: "\ue67c",
  shirt: "\ue67d",
  cart: "\ue67e",
  cartEmpty: "\ue67f",
  cartFull: "\ue680",
  tag: "\ue681",
  tags: "\ue682",
  cash: "\ue683",
  creditCard: "\ue684",
  barcode: "\ue685",
  barcode2: "\ue686",
  barcode3: "\ue687",
  phone: "\ue688",
  phone2: "\ue689",
  pin: "\ue68a",
  mapMarker: "\ue68b",
  compass: "\ue68c",
  map: "\ue68d",
  location: "\ue68e",
  roadSign: "\ue68f",
  calendar: "\ue690",
  calendar2: "\ue691",
  calendar3: "\ue692",
  mouse: "\ue693",
  keyboard: "\ue694",
  delete: "\ue695",
  spellCheck: "\ue696",
  screen: "\ue697",
  signal: "\ue698",
  iphone: "\ue699",
  smartphone: "\ue69a",
  ipad: "\ue69b",
  tablet: "\ue69c",
  laptop: "\ue69d",
  desktop: "\ue69e",
  radio: "\ue69f",
  tv: "\ue6a0",
  power: "\ue6a1",
  lightningBolt: "\ue6a2",
  lamp: "\ue6a3",
  plugCord: "\ue6a4",
  outlet: "\ue6a5",
  drawer: "\ue6a6",
  drawer2: "\ue6a7",
  drawer3: "\ue6a8",
  archive: "\ue6a9",
  archive2: "\ue6aa",
  comment: "\ue6ab",
  comments: "\ue6ac",
  chat: "\ue6ad",
  quoteOpen: "\ue6ae",
  quoteClose: "\ue6af",
  pulse: "\ue6b0",
  syringe: "\ue6b1",
  firstAid: "\ue6b2",
  lifebuoy: "\ue6b3",
  patch: "\ue6b4",
  patch2: "\ue6b5",
  lab: "\ue6b6",
  skull: "\ue6b7",
  construction: "\ue6b8",
  constructionCone: "\ue6b9",
  pieChart: "\ue6ba",
  pieChart2: "\ue6bb",
  graph: "\ue6bc",
  chartGrowth: "\ue6bd",
  cake: "\ue6be",
  gift: "\ue6bf",
  balloon: "\ue6c0",
  rank: "\ue6c1",
  rank2: "\ue6c2",
  rank3: "\ue6c3",
  crown: "\ue6c4",
  lotus: "\ue6c5",
  diamond: "\ue6c6",
  diamond2: "\ue6c7",
  diamond3: "\ue6c8",
  diamond4: "\ue6c9",
  linearicons: "\ue6ca",
  teacup: "\ue6cb",
  glass: "\ue6cc",
  bottle: "\ue6cd",
  cocktailGlass: "\ue6ce",
  dinner: "\ue6cf",
  dinner2: "\ue6d0",
  hamburger: "\ue6d1",
  dumbbell: "\ue6d2",
  apple: "\ue6d3",
  leaf: "\ue6d4",
  pineTree: "\ue6d5",
  tree: "\ue6d6",
  paw: "\ue6d7",
  paw2: "\ue6d8",
  footprint: "\ue6d9",
  speedSlow: "\ue6da",
  speedMedium: "\ue6db",
  speedFast: "\ue6dc",
  rocket: "\ue6dd",
  gamepad: "\ue6de",
  dice: "\ue6df",
  ticket: "\ue6e0",
  hammer2: "\ue6e1",
  balance: "\ue6e2",
  briefcase: "\ue6e3",
  plane: "\ue6e4",
  gas: "\ue6e5",
  transmission: "\ue6e6",
  car: "\ue6e7",
  bus: "\ue6e8",
  truck: "\ue6e9",
  trailer: "\ue6ea",
  train: "\ue6eb",
  ship: "\ue6ec",
  anchor: "\ue6ed",
  boat: "\ue6ee",
  bicycle: "\ue6ef",
  cube: "\ue6f0",
  puzzle: "\ue6f1",
  glasses: "\ue6f2",
  accessibility: "\ue6f3",
  wheelchir: "\ue6f4",
  icons: "\ue6f5",
  icons2: "\ue6f6",
  sitemap: "\ue6f7",
  earth: "\ue6f8",
  happy: "\ue6f9",
  smile: "\ue6fa",
  grin: "\ue6fb",
  tongue: "\ue6fc",
  sad: "\ue6fd",
  wink: "\ue6fe",
  dream: "\ue6ff",
  shocked: "\ue700",
  shocked2: "\ue701",
  tongue2: "\ue702",
  neutral: "\ue703",
  happyGrin: "\ue704",
  cool: "\ue705",
  mad: "\ue706",
  grinEvil: "\ue707",
  evil: "\ue708",
  shocked3: "\ue709",
  annoyed: "\ue70a",
  mustache: "\ue70b",
  wondering: "\ue70c",
  confused: "\ue70d",
  bell: "\ue70e",
  bullhorn: "\ue70f",
  volumeHigh: "\ue710",
  volumeMedium: "\ue711",
  volumeLow: "\ue712",
  volume: "\ue713",
  mute: "\ue714",
  wifi: "\ue715",
  wifi2: "\ue716",
  wifi3: "\ue717",
  mic: "\ue718",
  mic2: "\ue719",
  micMute: "\ue71a",
  hourglass: "\ue71b",
  loading: "\ue71c",
  loading2: "\ue71d",
  loading3: "\ue71e",
  undo: "\ue71f",
  redo: "\ue720",
  sync: "\ue721",
  sync2: "\ue722",
  refresh: "\ue723",
  refresh2: "\ue724",
  history: "\ue725",
  history2: "\ue726",
  clock: "\ue727",
  clock2: "\ue728",
  clock3: "\ue729",
  clock4: "\ue72a",
  clock5: "\ue72b",
  timer: "\ue72c",
  timer2: "\ue72d",
  download: "\ue72e",
  upload: "\ue72f",
  arrowUp: "\ue730",
  arrowDown: "\ue731",
  arrowLeft: "\ue732",
  arrowRight: "\ue733",
  arrowUp2: "\ue734",
  arrowDown2: "\ue735",
  arrowLeft2: "\ue736",
  arrowRight2: "\ue737",
  arrowUp3: "\ue738",
  arrowDown3: "\ue739",
  arrowLeft3: "\ue73a",
  arrowRight3: "\ue73b",
  arrowUp4: "\ue73c",
  arrowDown4: "\ue73d",
  arrowLeft4: "\ue73e",
  arrowRight4: "\ue73f",
  terminal: "\ue740",
  bug: "\ue741",
  code: "\ue742",
  fileCode: "\ue743",
  fileImage: "\ue744",
  fileZip: "\ue745",
  fileAudio: "\ue746",
  fileVideo: "\ue747",
  link: "\ue748",
  link2: "\ue749",
  unlink: "\ue74a",
  link3: "\ue74b",
  unlink2: "\ue74c",
  thumbsUp: "\ue74d",
  thumbsDown: "\ue74e",
  thumbsUp2: "\ue74f",
  thumbsDown2: "\ue750",
  thumbsUp3: "\ue751",
  thumbsDown3: "\ue752",
  share: "\ue753",
  share2: "\ue754",
  share3: "\ue755",
  options: "\ue756",
  list: "\ue757",
  list2: "\ue758",
  magnifier: "\ue759",
  zoomIn: "\ue75a",
  zoomOut: "\ue75b",
  question: "\ue75c",
  checkmark: "\ue75d",
  cross: "\ue75e",
  chevronUp: "\ue75f",
  chevronDown: "\ue760",
  chevronLeft: "\ue761",
  chevronRight: "\ue762",
  arrowUp5: "\ue763",
  arrowDown5: "\ue764",
  arrowLeft5: "\ue765",
  arrowRight5: "\ue766",
  expand: "\ue767",
  shrink: "\ue768",
  expand2: "\ue769",
  shrink2: "\ue76a",
  move: "\ue76b",
  tab: "\ue76c",
  warning: "\ue76d",
  circleExclamation: "\ue76e",
  circleQuestion: "\ue76f",
  circleCheckmark: "\ue770",
  circleCross: "\ue771",
  circlePlus: "\ue772",
  circleMinus: "\ue773",
  circleUp: "\ue774",
  circleDown: "\ue775",
  circleLeft: "\ue776",
  circleRight: "\ue777",
  circleUp2: "\ue778",
  circleDown2: "\ue779",
  circleLeft2: "\ue77a",
  circleRight2: "\ue77b",
  circleBackward: "\ue77c",
  circleFirst: "\ue77d",
  circlePrevious: "\ue77e",
  circleStop: "\ue77f",
  circlePlay: "\ue780",
  circlePause: "\ue781",
  circleNext: "\ue782",
  circleLast: "\ue783",
  circleForward: "\ue784",
  circleEject: "\ue785",
  crop: "\ue786",
  frame: "\ue787",
  ruler: "\ue788",
  funnel: "\ue789",
  flipHorizontal: "\ue78a",
  flipVertical: "\ue78b",
  subtract: "\ue78c",
  combine: "\ue78d",
  intersect: "\ue78e",
  exclude: "\ue78f",
  alignCenterVertical: "\ue790",
  alignRight: "\ue791",
  alignBottom: "\ue792",
  alignLeft: "\ue793",
  alignCenterHorizontal: "\ue794",
  alignTop: "\ue795",
  square: "\ue796",
  circle: "\ue797",
  uni21: "\u0021",
  uni22: "\u0022",
  uni23: "\u0023",
  uni24: "\u0024",
  uni25: "\u0025",
  uni26: "\u0026",
  uni27: "\u0027",
  uni28: "\u0028",
  uni29: "\u0029",
  uni2a: "\u002a",
  uni2b: "\u002b",
  uni2c: "\u002c",
  uni2d: "\u002d",
  uni2e: "\u002e",
  uni2f: "\u002f",
  uni30: "\u0030",
  uni31: "\u0031",
  uni32: "\u0032",
  uni33: "\u0033",
  uni34: "\u0034",
  uni35: "\u0035",
  uni37: "\u0037",
  uni38: "\u0038",
  uni39: "\u0039",
  uni3a: "\u003a",
  uni3b: "\u003b",
  uni3c: "\u003c",
  uni3d: "\u003d",
  uni3e: "\u003e",
  uni3f: "\u003f",
  uni40: "\u0040",
  uni41: "\u0041",
  uni42: "\u0042",
  uni43: "\u0043",
  uni44: "\u0044",
  uni45: "\u0045",
  uni46: "\u0046",
  uni47: "\u0047",
  uni48: "\u0048",
  uni49: "\u0049",
  uni4a: "\u004a",
  uni4b: "\u004b",
  uni4c: "\u004c",
  uni4d: "\u004d",
  uni4e: "\u004e",
  uni4f: "\u004f",
  uni50: "\u0050",
  uni51: "\u0051",
  uni52: "\u0052",
  uni53: "\u0053",
  uni54: "\u0054",
  uni55: "\u0055",
  uni56: "\u0056",
  uni57: "\u0057",
  uni58: "\u0058",
  uni59: "\u0059",
  uni5a: "\u005a",
  uni5b: "\u005b",
  uni5c: "\u005c",
  uni5d: "\u005d",
  uni5e: "\u005e",
  uni5f: "\u005f",
  uni60: "\u0060",
  uni61: "\u0061",
  uni62: "\u0062",
  uni63: "\u0063",
  uni64: "\u0064",
  uni65: "\u0065",
  uni66: "\u0066",
  uni67: "\u0067",
  uni68: "\u0068",
  uni69: "\u0069",
  uni6a: "\u006a",
  uni6b: "\u006b",
  uni6c: "\u006c",
  uni6d: "\u006d",
  uni6e: "\u006e",
  uni6f: "\u006f",
  uni70: "\u0070",
  uni71: "\u0071",
  uni72: "\u0072",
  uni73: "\u0073",
  uni74: "\u0074",
  uni75: "\u0075",
  uni76: "\u0076",
  uni77: "\u0077",
  uni78: "\u0078",
  uni79: "\u0079",
  uni7a: "\u007a",
  uni7b: "\u007b",
  uni7c: "\u007c",
  uni7d: "\u007d",
  uni7e: "\u007e",
  copyright: "\u00a9"
};